import ContentBox from '../../shared/ContentBox'
import React from 'react'
import TasksTable from './TasksTable'
import { useAppContext } from '../../../contextproviders/AppContext'
import { TasksPageContextProvider, useTasksPageContext } from '../../../contextproviders/TasksPageContext'
import LoadableContent from '../../shared/LoadableContent'

function TasksPage () {
  const appContext = useAppContext()

  return (
    <TasksPageContextProvider appContext={appContext}>
      <TasksPageContent />
    </TasksPageContextProvider>
  )
}

function TasksPageContent () {
  const { isFetchingData } = useTasksPageContext()
  return (
    <LoadableContent isLoading={isFetchingData}>
      <ContentBox>
        <TasksTable />
      </ContentBox>
    </LoadableContent>
  )
}

export default TasksPage
