import CostsTrendingArrow from './CostsTrendingArrow'
import { formatCurrency } from '../../../../helper/costs'

function CostSummaryItem({
  label = '',
  amount = 0,
  amountToCompareAgainst = 0,
}) {
  return (
    <div
      className="column
    is-one-third-desktop
    is-full-tablet
    divider-right
    is-flex
    is-flex-wrap-wrap
    is-align-items-flex-start
    is-align-content-center
    is-flex-direction-row"
    >
      <h3 className="is-size-6 has-text-centered" style={{ width: '100%' }}>
        {label}
      </h3>
      <h4
        className="is-size-3 has-text-primary has-text-centered mb-1"
        style={{ width: '100%' }}
      >
        {formatCurrency(Math.round(amount))}
      </h4>
      <div className="has-text-centered" style={{ width: '100%' }}>
        <CostsTrendingArrow
          amount={amount}
          baseAmount={amountToCompareAgainst}
        />
      </div>
    </div>
  )
}

export default CostSummaryItem
