import Rselect from "react-select";
function MultiSelect({
  label = "",
  selected,
  value,
  onChange,
  options,
  formatOptionLabel,
  placeholder = "",
  className = "",
  id = "",
  disabled = false,
  controlShouldRenderValue = true,
  filterOption,
  menuBoxWidth = "",
  menuBoxHeight = "",
}) {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "0px",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#003087",
    }),
    menu: (base) => ({
      ...base,
      width: menuBoxWidth || base.width,
    }),
    menuList: (base) => ({
      ...base,
      width: menuBoxWidth || base.width,
    }),
  };

  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <div className="control">
        <Rselect
          styles={customStyles}
          placeholder={placeholder}
          value={value}
          isMulti
          filterOption={filterOption}
          maxMenuHeight={menuBoxHeight}
          options={options}
          formatOptionLabel={formatOptionLabel}
          onChange={onChange}
          controlShouldRenderValue={controlShouldRenderValue}
        />
      </div>
    </div>
  );
}

export default MultiSelect;
