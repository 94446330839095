import SortableTable from '../../shared/SortableTable'
import { useTranslation } from 'react-i18next'
import 'moment/locale/fi'
import { useCostsPageContext } from 'contextproviders/CostsPageContext'

const CostsTable = ({ services = [], billing = [] }) => {
  const { t } = useTranslation()
  const { costs } = useCostsPageContext()

  //helper function for object mapping
  const objectMap = (obj, fn) =>
    Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

  const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'Juny',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const costsDataBySite = (data) =>
    'costsBySite' in data ? data.costsBySite : []

  const getSum = (array) => array.reduce((acc, value) => acc + Number(value), 0)

  const filteredServiceAndBilling = (report) =>
    services.includes(report.service) && billing.includes(report.type)

  const monthlyCostsDataBySite = costsDataBySite(costs.data)
    .map((site) => {
      const reportsByMonth = site.reports.reduce((acc, report) => {
        const month = MONTHS[report.month - 1]
        return filteredServiceAndBilling(report)
          ? {
              ...acc,
              [month]: [...(acc[month] || []), report.cost],
            }
          : acc
      }, {})
      const costSumsByMonth = objectMap(reportsByMonth, getSum)
      return { ...costSumsByMonth, site: site.site_name }
    })
    .filter((site) => Object.keys(site).length > 1)

  // if we want to show sites with no costs, better sort them so that empty sites are bottom
  // function sortByCosts(a, b) {
  //   const aKeyCount = Object.keys(a).length
  //   const bKeyCount = Object.keys(b).length
  //   if (aKeyCount > bKeyCount) {
  //     return -1
  //   } else if (aKeyCount < bKeyCount) {
  //     return 1
  //   }
  //   return 0
  // }

  // const monthlyCostsDataBySiteSortedByContent =
  //   monthlyCostsDataBySite.sort(sortByCosts)

  const tableColumns = [
    {
      Header: t('Site'),
      accessor: 'site',
      disableFilters: true,
      Cell: (row) => <div style={{ fontSize: '1rem' }}>{row.value}</div>,
    },
    ...MONTHS.map((month) => ({
      Header: t(month),
      accessor: `${month}`,
      disableFilters: true,
      Cell: (row) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            fontSize: '1rem',
            padding: '16px 0px 16px 4px',
          }}
        >
          {row.value ? `${Math.round(row.value)} €` : null}
        </div>
      ),
    })),
  ]

  return monthlyCostsDataBySite.length > 0 ? (
    <div className="calendar-tracking-table">
      <SortableTable
        columns={tableColumns}
        data={monthlyCostsDataBySite}
        showBottomRowStats={false}
      />
    </div>
  ) : null
}

export default CostsTable
