import { filterByYear } from './charts/charts.helper'

export const COST_TYPE = {
  ADDITIONAL: 'ADDITIONAL',
  CONTRACT: 'CONTRACT'
}

const filterCostsToCurrentMonth = (costs = [], currentMonth) => {
  if (costs) {
    // add 1 month to currentMonth to include it,
    // because moment uses 0-11 indexes for month numbers
    return costs.filter(data => data.month <= currentMonth + 1)
  }
}

export const getYTDCostsSum = (costs = [], year, month) => {
  const previousYearData = filterByYear(costs, year)
  let sum = 0

  previousYearData.forEach(d => {
    const dataWithYTDMonths = filterCostsToCurrentMonth(d.costs, month)
    dataWithYTDMonths.forEach(month => {
      sum += month.cost
    })
  })

  return sum
}

/**
 * @param {number} amount value for what the trending percentage is being calculated to
 * @param {number} baseAmount value that amount is compared to for calculating trending percetage
 * @returns positive or negative whole number which indicates trending of
 * amount compared to base amount as percentage
 */
export const calculateTrendingPercentage = (amount = 0, baseAmount = 0) => {
  if (!amount || !baseAmount) return 0
  const percentage = amount / baseAmount * 100
  return Math.round(percentage - 100)
}

export const formatCurrency = (value) => {
  return value
    ? new Intl.NumberFormat(
        'fi-FI',
        { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(value)
    : value
}
