import React, { useEffect, useState } from 'react'
import { OPTION_ALL } from '../helper/constants'
import useTasks from '../hooks/useTasks'

export const TasksPageContext = React.createContext()

export const TasksPageContextProvider = ({ appContext, children }) => {
  const { selectedCustomer, selectedSiteId } = appContext
  const tasks = useTasks(selectedCustomer?.id, selectedSiteId)
  const [isFetchingData, setIsFetchingData] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  useEffect(() => {
    if (selectedCustomer && selectedSiteId) {
      fetchTasksData()
    }
    setIsInitialLoad(false)
  }, [])

  useEffect(() => {
    // When selected site is changed -> fetch data from REST API
    if (!isInitialLoad && selectedCustomer && selectedSiteId) {
      fetchTasksData()
    }
  }, [selectedSiteId])

  useEffect(() => {
    // Only fetch data again when selected customer has changed and selected site equals OPTION_ALL
    // this will only trigger when selectedSiteId equals OPTION_ALL before and after selected customer change
    // because selected customer change precedes selected site change
    if (!isInitialLoad && selectedCustomer && selectedSiteId === OPTION_ALL) {
      fetchTasksData()
    }
  }, [selectedCustomer])

  const fetchTasksData = async () => {
    setIsFetchingData(true)
    try {
      await tasks.reload()
    } catch (e) {
      console.error(e)
    }
    setIsFetchingData(false)
  }

  const values = {
    isFetchingData,
    tasks,
  }

  return (
    <TasksPageContext.Provider value={values}>
      {children}
    </TasksPageContext.Provider>
  )
}

export const useTasksPageContext = () => {
  return React.useContext(TasksPageContext)
}
