import ContentBox from '../../shared/ContentBox'
import React from 'react'
import CostsChart from './CostsChart'
import CostsSummary from './CostsSummary'
import LoadableContent from '../../shared/LoadableContent'
import {
  CostsPageContextProvider,
  useCostsPageContext,
} from '../../../contextproviders/CostsPageContext'
import { useAppContext } from '../../../contextproviders/AppContext'

function CostsPage() {
  const appContext = useAppContext()

  return (
    <CostsPageContextProvider appContext={appContext}>
      <CostsPageContent />
    </CostsPageContextProvider>
  )
}

function CostsPageContent() {
  const { isFetchingData } = useCostsPageContext()

  return (
    <LoadableContent isLoading={isFetchingData}>
      <ContentBox>
        <CostsSummary />
      </ContentBox>
      <ContentBox>
        <CostsChart />
      </ContentBox>
    </LoadableContent>
  )
}

export default CostsPage
