import { formatCurrency } from '../costs'

export const defaultOptions = {
  scales: {
    x: {
      display: true,
      title: {
        display: true
      },
      grid: {
        display: false,
        drawBorder: false
      }
    },
    y: {
      display: true,
      title: {
        display: true
      },
      grid: {
        drawBorder: false
      },
      min: 0,
      max: 4
    }
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 15,
        padding: 30
      }
    },
    datalabels: {
      display: false
    }
  }
}

export const costsChartOptions = {
  layout: {
    padding: {
      top: 30
    }
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true
      },
      grid: {
        display: false,
        drawBorder: false
      },
      stacked: true
    },
    y: {
      display: true,
      title: {
        display: true
      },
      grid: {
        drawBorder: false
      },
      ticks: {
        callback: function (value, index, values) {
          return formatCurrency(value)
        }
      },
      stacked: true
    }
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 15,
        padding: 30
      }
    },
    datalabels: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || ''

          if (label) {
            label += ': '
          }
          if (context.parsed.y !== null) {
            label += formatCurrency(context.parsed.y)
          }
          return label
        }
      }
    }
  }
}

export const doughnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      title: {
        display: true
      },
      labels: {
        boxWidth: 15
      }
    },
    datalabels: {
      formatter: (value, context) => {
        return value
          ? formatCurrency(value)
          : null
      },
      font: {
        size: 14
      }
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          let label = context.label || ''

          if (label) {
            label += ': '
          }
          if (context.parsed !== null) {
            label += formatCurrency(context.parsed)
          }
          return label
        }
      }
    }
  },
  layout: {
    autoPadding: false,
    padding: 30
  }
}

export const chartColors = [
  {
    default: '#509E2F',
    light: '#ddefd5'
  },
  {
    default: '#008C95',
    light: '#c8ebee'
  },
  {
    default: '#003087',
    light: '#99b0db'
  },
  {
    default: '#003087',
    light: '#99b0db'
  },
  {
    default: '#003087',
    light: '#99b0db'
  } //TODO add more colors for each year
]

export const chartTransparentColors = [
  'rgba(80,158,47,0.25)',
  'rgba(173,210,109,0.25)'
]
