import { chartColors } from './charts.options'

export function sumCellsOfArrays (arrays) {
  if (arrays.length <= 0) {
    return []
  }

  const results = Array(arrays[0].length).fill(0)

  for (const arr of arrays) {
    for (let i = 0; i < arr.length; i++) {
      results[i] += arr[i]
    }
  }

  return results
}

export function filterByCostType (data = [], type) {
  return type
    ? data.filter(d => d.type === type)
    : data
}

export function filterByYear (data = [], year) {
  return year
    ? data.filter(d => d.year === year)
    : data
}

export function filterByService (data = [], service) {
  return service
    ? data.filter(d => d.service === service)
    : data
}

export function handleCostTrackingData (data) {
  // Group data by type and sum costs for each month
  if (!data) {
    return []
  }

  const result = []
  const yearSet = new Set(data.map(d => d.year))
  const years = [...yearSet]

  for (let i = 0; i < years.length; i++) {
    const year = years[i]
    const dataOfYear = filterByYear(data, year)

    const costsData = dataOfYear.map((d) => {
      return d.costs?.map(c => c.cost)
    })

    const costsFlat = sumCellsOfArrays(costsData)

    result.push({
      label: year,
      data: costsFlat
    })
  }

  return result
}

export function handleCostTrackingStackedData (data, billingTypes = []) {
  if (!data) {
    return []
  }

  const result = []
  const yearSet = new Set(data.map(d => d.year))
  const years = [...yearSet]

  const sortedBillingTypes =
    (billingTypes.sort((a, b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0)))

  for (let i = 0; i < years.length; i++) {
    const year = years[i]
    const dataOfYear = filterByYear(data, year)

    sortedBillingTypes.forEach((type, j) => {
      const label = `${type.label} ${years[i]}`
      const stack = `Stack ${i}`

      const data = dataOfYear.filter(d => d.type === type.value)

      const costsData = data.map((d) => {
        return d.costs?.map(c => c.cost)
      })

      const costsFlat = sumCellsOfArrays(costsData)

      result.push({
        label: label,
        data: costsFlat,
        stack: stack,
        backgroundColor: j === 0 ? chartColors[i].default : chartColors[i].light
      })
    })
  }
  return result
}

export function transformToCostTrackingDataset (data, label, backgroundColor, stack) {
  return data && label
    ? {
        borderColor: 'rgba(0,0,0,0)',
        borderWidth: {
          left: 3,
          right: 3
        },
        barThickness: 25,
        maxBarThickness: 25,
        label: label,
        data: data,
        backgroundColor: backgroundColor,
        stack: stack
      }
    : { label: '', data: [] }
}

export function getChartDataset (labels = [], datasets = []) {
  return {
    labels: labels,
    datasets: datasets
  }
}
