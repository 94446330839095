import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DoughnutChart from '../../../shared/charts/DoughnutChart'
import {
  chartColors,
  doughnutChartOptions,
} from '../../../../helper/charts/charts.options'
import { filterByService } from '../../../../helper/charts/charts.helper'

const initialDoughnutDataset = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [],
      hoverOffset: 1,
      borderWidth: 0,
      cutout: '70%',
    },
  ],
}

function CostSummaryDoughnut({ data = [], currentYear }) {
  const { t } = useTranslation()
  const [doughnutChartDataset, setDoughnutChartDataset] = useState(
    initialDoughnutDataset
  )

  const getSeparateServicesFromData = (data) => {
    return [...new Set(data.map((item) => item.service))]
  }

  const getDatasetForChart = (data, services) => {
    const dataSet = []
    services.forEach((s, i) => {
      let serviceTotalSum = 0
      const serviceSpecificData = filterByService(data, s)
      serviceSpecificData.forEach((d) => {
        d.costs.forEach((cost) => {
          serviceTotalSum += cost.cost
        })
      })
      dataSet.push({
        label: s,
        sum: serviceTotalSum,
        backgroundColor: chartColors[i] ? chartColors[i].default : '#404040',
      })
    })
    return dataSet
  }

  const convertDatasetToRightFormat = (dataset) => {
    const finalDataset = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverOffset: 3,
          spacing: 2,
        },
      ],
    }
    dataset.forEach((d) => {
      if (d.sum > 0) {
        finalDataset.labels.push(d.label)
        finalDataset.datasets[0].data.push(d.sum)
        finalDataset.datasets[0].backgroundColor.push(d.backgroundColor)
      }
    })
    return finalDataset
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const services = getSeparateServicesFromData(data)
      const dataset = getDatasetForChart(data, services)
      setDoughnutChartDataset(convertDatasetToRightFormat(dataset))
    }
    return () => setDoughnutChartDataset(initialDoughnutDataset)
  }, [data])

  return (
    <div className="column is-one-third-desktop is-full-tablet has-text-centered">
      {data.length < 1 ? (
        <p>{t('No results.')}</p>
      ) : (
        <div style={{ height: '300px' }}>
          <DoughnutChart
            data={doughnutChartDataset}
            options={doughnutChartOptions}
          />
        </div>
      )}
    </div>
  )
}

export default CostSummaryDoughnut
