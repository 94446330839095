import { useState } from 'react'
import {
  getAPIClient,
  getDataFromResponse,
  getSitePathParam,
  convertFieldValueIfValueMatches
} from '../helper/axios.client'
import { errorFlag } from '../helper/messages'
import i18n from 'i18next'

function useCostsSummary (customerId = '', siteId = '') {
  const client = getAPIClient('costs/summary/')
  const [data, setData] = useState([])

  const getCostsSummary = async () => {
    const siteIdParam = getSitePathParam(siteId)
    return await client.get(`/${customerId}${siteIdParam}`)
  }

  // rename Siivous-service with more descriptive name
  const renameService = (data) => {
    return convertFieldValueIfValueMatches(
      data,
      'service',
      'Siivous',
      'Siivous- ja tukipalvelut'
    )
  }

  const reload = async () => {
    try {
      const response = await getCostsSummary()
      const data = renameService(getDataFromResponse(response.data.data, 'costs_summary'))
      setData(data)
    } catch (e) {
      console.error(e)
      errorFlag(i18n.t('Error fetching data'))
    }
  }

  return { data, reload }
}

export default useCostsSummary
