import ContentBoxNoPadding from 'components/shared/ContentBoxNoPadding'

import { localeNumberWithDecimals } from '../../utils'
import { Bar } from '../bar/bar'

export const Card = ({ label, amount, scopes }) => {
  return (
    <ContentBoxNoPadding>
      <h3
        style={{
          width: '100%',
          fontWeight: '700',
          fontSize: '1.5rem',
        }}
      >
        {label}
      </h3>
      <h4 style={{ width: '100%', fontWeight: '700', fontSize: '3rem' }}>
        {localeNumberWithDecimals(amount / 1000, 3)}
      </h4>
      <p style={{ fontWeight: '700' }}>Tonnia CO2e</p>
      <div className="has-text-centered" style={{ width: '100%' }}></div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Bar tiny navy={scopes[0]} blue={scopes[1]} lightBlue={scopes[2]} />
        {scopes.map((scope, i) => {
          return (
            <div
              className="is-flex is-justify-content-space-between"
              key={`scope ${i}`}
            >
              <p>Scope {i+1}</p>
              <p>
                <strong>{localeNumberWithDecimals(scope / 1000, 2)}</strong>{' '}
                tCO2e
              </p>
            </div>
          )
        })}
      </div>
    </ContentBoxNoPadding>
  )
}
