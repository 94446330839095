import { useTranslation } from 'react-i18next'
import { useCostsPageContext } from '../../../contextproviders/CostsPageContext'
import { Card } from './Components/cards/Card'
import {
  getTotalSum,
  getCostsByYearAndService,
  CLEANING_SERVICES,
  MAINTENANCE_SERVICES,
  convertCostToEmissions,
  emissionToScopeConversionRates,
} from './utils'

function CarbonFootprintSummary({ chosenYear }) {
  const { t } = useTranslation()
  const { costs } = useCostsPageContext()
  const dataCostsSummary = (data) =>
    'costs_summary' in data ? data.costs_summary : []

  const cleaningCosts = getTotalSum(
    getCostsByYearAndService(dataCostsSummary(costs.data), chosenYear, CLEANING_SERVICES)
  )

  const maintenanceCosts = getTotalSum(
    getCostsByYearAndService(dataCostsSummary(costs.data), chosenYear, MAINTENANCE_SERVICES)
  )

  const cleaningEmissions = convertCostToEmissions(
    cleaningCosts,
    chosenYear,
    CLEANING_SERVICES
  )

  const maintenanceEmissions = convertCostToEmissions(
    maintenanceCosts,
    chosenYear,
    MAINTENANCE_SERVICES
  )

  const cleaningScopes = [...Array(3)].map(
    (item, i) =>
      cleaningEmissions *
      emissionToScopeConversionRates[CLEANING_SERVICES][chosenYear][i]
  )

  const maintenanceScopes = [...Array(3)].map(
    (item, i) =>
      maintenanceEmissions *
      emissionToScopeConversionRates[MAINTENANCE_SERVICES][chosenYear][i]
  )

  const totalScopes = [...Array(3)].map(
    (item, i) => cleaningScopes[i] + maintenanceScopes[i]
  )

  return (
    <div className="cards-wrapper">
      <Card
        label={t('Total')}
        amount={cleaningEmissions + maintenanceEmissions}
        scopes={totalScopes}
      />
      <Card
        label={t('Cleaning')}
        amount={cleaningEmissions}
        scopes={cleaningScopes}
      />
      <Card
        label={t('Property maintenance')}
        amount={maintenanceEmissions}
        scopes={maintenanceScopes}
      />
    </div>
  )
}

export default CarbonFootprintSummary
