import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import VerticalBarChart from '../../shared/charts/VerticalBarChart'
import {
  chartColors,
  costsChartOptions,
} from '../../../helper/charts/charts.options'
import { getMonthLabelsWithCapitalLetters } from '../../../helper/dates'
import { formatCurrency } from '../../../helper/costs'
import moment from 'moment'
import {
  handleCostTrackingData,
  handleCostTrackingStackedData,
  filterByYear,
  transformToCostTrackingDataset,
  getChartDataset,
} from '../../../helper/charts/charts.helper'
import MultiSelect from '../../shared/form/MultiSelect'
import Tag from '../../shared/Tag'
import { useCostsPageContext } from '../../../contextproviders/CostsPageContext'
import CostsTable from './CostsTable'

function CostsChart() {
  const { t } = useTranslation()
  const { costs } = useCostsPageContext()
  const currentYear = moment().year()
  const monthLabels = getMonthLabelsWithCapitalLetters()
  const chartOptions = costsChartOptions

  const [selectedServices, setSelectedServices] = useState([])
  const [selectedBilling, setSelectedBilling] = useState([])
  const [costsChartDataset, setCostsChartDataset] = useState([])
  const [currentYearCostsSum, setCurrentYearCostsSum] = useState(0)
  const getOptionsFromData = (data, fieldName) => {
    const uniques = [...new Set(data.map((item) => item[fieldName]))]
    const options = []

    uniques.forEach((unique) => [
      options.push({ value: unique, label: t(`${unique}`) }),
    ])

    return options
  }

  const dataCostsSummary = (data) =>
    'costs_summary' in data ? data.costs_summary : []

  const serviceOptions = useMemo(
    () => getOptionsFromData(dataCostsSummary(costs.data), 'service'),
    [costs.data]
  )
  const billingTypeOptions = useMemo(
    () => getOptionsFromData(dataCostsSummary(costs.data), 'type'),
    [costs.data]
  )

  const handleSelectedServiceChange = (selectedOptions) => {
    setSelectedServices(selectedOptions)
  }

  const handleSelectedBillingChange = (selectedOptions) => {
    setSelectedBilling(selectedOptions)
  }

  const handleDeleteServiceClick = (value) => {
    setSelectedServices((services) => services.filter((s) => s.value !== value))
  }

  const handleDeleteBillingTypeClick = (value) => {
    setSelectedBilling((billingTypes) =>
      billingTypes.filter((b) => b.value !== value)
    )
  }

  const filterDataByServices = (data = [], services) => {
    return data.filter((d) => services.includes(d.service))
  }

  const filterDataByBillingTypes = (data = [], types) => {
    return data.filter((d) => types.includes(d.type))
  }

  const setChartData = (costsData) => {
    if (!costsData) return null

    if (selectedBilling.length > 0) {
      const billingTypes = selectedBilling
      const data = handleCostTrackingStackedData(costsData, billingTypes)
      const dataset = data.map((d, i) =>
        transformToCostTrackingDataset(
          d.data,
          t(d.label),
          d.backgroundColor,
          d.stack
        )
      )
      setCostsChartDataset(dataset)
    } else {
      const data = handleCostTrackingData(costsData)

      const dataset = data.map((d, i) =>
        transformToCostTrackingDataset(
          d.data,
          t(d.label),
          chartColors[i].default
        )
      )

      setCostsChartDataset(dataset)
    }
  }

  // filter chart data and count sum when selection on service or billing changes
  useEffect(() => {
    let data
    let sum = 0

    if (selectedServices.length > 0) {
      data = filterDataByServices(
        dataCostsSummary(costs.data),
        selectedServices.map((service) => service.value)
      )
    } else {
      data = []
    }

    if (selectedBilling.length > 0) {
      data = filterDataByBillingTypes(
        data,
        selectedBilling.map((type) => type.value)
      )
      // use stacked bars if there are 2 or more selected billing types
      if (selectedBilling.length > 1) {
        chartOptions.scales.x.stacked = true
        chartOptions.scales.y.stacked = true
      } else {
        chartOptions.scales.x.stacked = false
        chartOptions.scales.y.stacked = false
      }
    } else {
      data = []
    }

    const currentYearData = filterByYear(data, currentYear)
    currentYearData.forEach((d) => {
      d.costs.forEach((month) => {
        sum += month.cost
      })
    })

    setCurrentYearCostsSum(Math.round(sum))
    setChartData(data)
  }, [selectedServices, selectedBilling])

  // set all service and billing types as selected by default
  // cleanup filters and sum when data changes
  useEffect(() => {
    setSelectedServices(serviceOptions)
    setSelectedBilling(billingTypeOptions)
    return () => {
      setSelectedServices([])
      setSelectedBilling([])
      setCurrentYearCostsSum(0)
    }
  }, [costs])

  const getSelectedServices = () => {
    return (
      <div className="tags">
        {selectedServices.map((service, i) => {
          return (
            <Tag
              key={i}
              label={service.label}
              handleDeleteClick={() => handleDeleteServiceClick(service.value)}
            />
          )
        })}
      </div>
    )
  }

  const getSelectedBillingTypes = () => {
    return (
      <div className="tags">
        {selectedBilling.map((type, i) => {
          return (
            <Tag
              key={i}
              label={type.label}
              handleDeleteClick={() => handleDeleteBillingTypeClick(type.value)}
            />
          )
        })}
      </div>
    )
  }

  const noResults = () => {
    return <p className="has-text-centered">{t('No results.')}</p>
  }

  const getCostsChart = () => {
    if (costsChartDataset.length) {
      return (
        <VerticalBarChart
          data={getChartDataset(monthLabels, costsChartDataset)}
          options={chartOptions}
        />
      )
    } else return noResults()
  }

  const getServiceDropdownPlaceholder = () => {
    if (selectedServices.length > 0) {
      if (selectedServices.length === serviceOptions.length) {
        return t('All services selected')
      } else {
        return ''
      }
    } else return t('Select service')
  }

  const getBillingTypeDropdownPlaceholder = () => {
    if (selectedBilling.length > 0) {
      if (selectedBilling.length === billingTypeOptions.length) {
        return t('All billing types selected')
      } else {
        return ''
      }
    } else return t('Select type')
  }

  return (
    <>
      <nav className="level mb-2">
        <div className="level-left">
          <div className="level-item">
            <h2 className="title is-size-4">{t('Cost tracking')}</h2>
          </div>
        </div>
      </nav>

      <div className="content">
        <p className="is-size-6">
          {t('Credit memos are not included in totals')}
        </p>
        <div className="columns">
          <div className="column is-4">
            <MultiSelect
              label={t('Services')}
              placeholder={getServiceDropdownPlaceholder()}
              value={selectedServices}
              options={serviceOptions}
              onChange={handleSelectedServiceChange}
              controlShouldRenderValue={false}
            />
            {getSelectedServices()}
          </div>
          <div className="column is-4">
            <MultiSelect
              label={t('Billing type')}
              placeholder={getBillingTypeDropdownPlaceholder()}
              value={selectedBilling}
              options={billingTypeOptions}
              onChange={handleSelectedBillingChange}
              controlShouldRenderValue={false}
            />
            {getSelectedBillingTypes()}
          </div>
          <div
            className="column
                          is-4
                          is-flex
                          is-flex-wrap-wrap
                          is-align-content-flex-end
                          is-justify-content-flex-end
                          is-flex-direction-column"
          >
            <h2 className="is-size-6">{t('Current year')}</h2>
            <h4 className="is-size-3 has-text-primary mb-0">
              {formatCurrency(Math.round(currentYearCostsSum))}
            </h4>
          </div>
        </div>
        {getCostsChart()}
      </div>
      <CostsTable
        services={selectedServices.map((service) => service.value)}
        billing={selectedBilling.map((type) => type.value)}
      />
    </>
  )
}

export default CostsChart
