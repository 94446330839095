import { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useCostsPageContext } from '../../../contextproviders/CostsPageContext'
import CostSummaryItem from './Components/CostsSummaryItem'
import CostSummaryDoughnut from './Components/CostsSummaryDoughnut'
import { filterByYear } from '../../../helper/charts/charts.helper'
import { getYTDCostsSum, COST_TYPE } from '../../../helper/costs'

function CostsSummary() {
  const { t } = useTranslation()
  const { costs } = useCostsPageContext()
  const currentYear = moment().year()
  const currentMonth = moment().month()

  const initialState = {
    totalSum: 0,
    prevYearTotalSum: 0,
    additionalSum: 0,
    prevYearAdditionalSum: 0,
  }

  const [YTDSums, setYTDSums] = useState(initialState)

  const dataCostsSummary = (data) =>
    'costs_summary' in data ? data.costs_summary : []

  useEffect(() => {
    const additionalCostsData = dataCostsSummary(costs.data).filter(
      (cost) => cost.type === COST_TYPE.ADDITIONAL
    )

    setYTDSums((prevState) => ({
      ...prevState,
      totalSum: getYTDCostsSum(
        dataCostsSummary(costs.data),
        currentYear,
        currentMonth
      ),
      prevYearTotalSum: getYTDCostsSum(
        dataCostsSummary(costs.data),
        currentYear - 1,
        currentMonth
      ),
      additionalSum: getYTDCostsSum(
        additionalCostsData,
        currentYear,
        currentMonth
      ),
      prevYearAdditionalSum: getYTDCostsSum(
        additionalCostsData,
        currentYear - 1,
        currentMonth
      ),
    }))
    return () => {
      setYTDSums(initialState)
    }
  }, [costs])

  return (
    <div className="content">
      <h2 className="title is-size-4">{`${t('Summary')} (${currentYear})`}</h2>
      <div className="columns is-multiline">
        <CostSummaryItem
          label={t('Total costs')}
          amount={YTDSums.totalSum}
          amountToCompareAgainst={YTDSums.prevYearTotalSum}
        />
        <CostSummaryItem
          label={t('Additional costs')}
          amount={YTDSums.additionalSum}
          amountToCompareAgainst={YTDSums.prevYearAdditionalSum}
        />
        <CostSummaryDoughnut
          data={filterByYear(dataCostsSummary(costs.data), currentYear)}
          currentYear={currentYear}
        />
      </div>
    </div>
  )
}

export default CostsSummary
